<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--기간-->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="심사기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <!--안전보건 내부심사 수행계획서 목록-->
    <c-table
      ref="table"
      title="LBL0010114"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--등록-->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action',
  data() {
    return {
      searchParam: {
        plantCd: null,
        period: [],
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'actionPeriod',
            field: 'actionPeriod',
            //심사기간
            label: 'LBL0010115',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'actionName',
            field: 'actionName',
            //수행계획서명
            label: 'LBL0010116',
            align: 'left',
            style: 'width:300px',
            sortable: true,
            type: 'link'
          },
          {
            name: 'targetDeptNames',
            field: 'targetDeptNames',
            //피심사팀
            label: 'LBL0010111',
            align: 'left',
            sortable: true,
          },
          {
            name: 'saiInternalActionStepName',
            field: 'saiInternalActionStepName',
            //진행상태
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            //진행상태
            label: '결재상태',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'saiInternalActionTypeName',
            field: 'saiInternalActionTypeName',
            //심사구분
            label: 'LBL0010117',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            //검토자
            label: 'LBLREVIEWER',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            //승인자
            label: 'LBLAPPROVER',
            style: 'width:100px',
            align: 'center',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.internal.action.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = 'LBL0010118';   //내부심사 수행계획서 상세
      this.popupOptions.param = {
        saiInternalActionId: row ? row.saiInternalActionId : '',
      };
      this.popupOptions.target = () => import(`${'./internalActionDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
